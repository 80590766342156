var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row no-gutters pb-2 mb-3 align-items-end SectionTitle"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('h3', {
    staticClass: "mb-0",
    class: _vm.titleClass
  }, [_vm._t("icon", function () {
    return [_vm.icon ? _c('i', {
      staticClass: "fa fa-sm text-primary mr-2",
      class: _vm.icon
    }) : _vm._e()];
  }), _vm._t("default", function () {
    return [_vm._v(_vm._s(_vm.title))];
  })], 2)]), _vm.$slots['actions'] ? _c('div', {
    staticClass: "text-right",
    class: `${_vm.actionsClass}`
  }, [_vm._t("actions")], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }